import { createContext, useEffect, useState } from "react";
import { getAllListsAccessibles, getUser } from "../api/axios";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        const user = localStorage.getItem("user");
        return JSON.parse(user) || {};
    });

    // update auth
    useEffect(() => {
        const userRaw = localStorage.getItem("user");
        if (!userRaw) {
            return;
        }

        const user = JSON.parse(userRaw);
        getUser(user.id).then(data => {
            if (data.data.is_active === true) {
                setAuth(data.data);
            } else {
                setAuth({});
            }
        });
    }, []);

    const [listsIds, setListsIds] = useState(null);
    useEffect(() => {
        if (!auth || !auth.company_id) {
            return;
        }

        getAllListsAccessibles(auth.company_id).then((data) => {
            setListsIds(data.data);
        });
    }, [auth]);

    const [color, setColor] = useState(null);
    const [secondaryColor, setSecondaryColor] = useState(null);
    const [webDomain, setWebdomain] = useState(null);
    const [logoURL, setLogoURL] = useState(null);
    useEffect(() => {
        const newWebDomain = window.location.hostname;
        console.log('webDomain :>> ', newWebDomain);
        setWebdomain(newWebDomain);
        if (newWebDomain === "app.alyor-call.fr") {
            setColor("rgb(75, 166, 100)");
            setSecondaryColor("rgb(247, 247, 245)");
            setLogoURL("./images/logo_alyor.svg"); // todo(3) mettre adresse absolue
        } else {
            setColor("#24398A");
            setSecondaryColor("rgb(232, 240, 254)");
            setLogoURL(null);  // todo(3) gerer ce qui s'affiche quand ces trucs sont nuls, surtout si ici on laisse a null pour pas importer logo
        }
    }, []);

    console.log('color :>> ', color);

    return (
        <AuthContext.Provider value={{ auth, setAuth, listsIds, setListsIds, webDomain, color, secondaryColor, logoURL }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { date2frenchString, getFrenchDayName } from "../../../lib/tools/datetime";
import { LeadStatus } from "../../../context/enums";


export default function TableDaysUsage({ daysUsageData }) {
    if (!daysUsageData) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="right">Leads travaillés</TableCell>
                        <TableCell align="right">{LeadStatus["RDV_HAPPENED"]}</TableCell>
                        <TableCell align="right">{LeadStatus["SIGNE"]}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {daysUsageData.map((row) => {
                        const dayStr = getFrenchDayName(new Date(row.day)) + " " + date2frenchString(new Date(row.day));
                        
                        return (
                            <TableRow key={row.day} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{dayStr}</TableCell>
                                <TableCell align="right">{row.work_done}</TableCell>
                                <TableCell align="right">{row.rdv_taken}</TableCell>
                                <TableCell align="right">{row.signings}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

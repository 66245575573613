import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import GraphUsersWork from "./graphs/graph_users_work";
import TableUsersUsage from "./tables/table_users_usage";
import TableDaysUsage from "./tables/tables_days_usage";
import { useDaysUsage } from "./userDaysUsage";
import { useUsersUsage } from "./userUsersUsage";


export default function CompanyMetrics({ setToast }) {
    const { auth, color } = useContext(AuthContext);

    const [duration, setDuration] = useState(14);

    const [daysUsageData] = useDaysUsage({ companyId: auth.company_id, duration: duration });
    const [usersUsageData] = useUsersUsage({ companyId: auth.company_id, duration: duration });

    if (!auth) {
        return null;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h1 className="title" style={{ color }}>{"Métriques"}</h1>
            </Grid>

            <Grid item xs={12} key="work_one">
                <h3 className="title" style={{ color }}>{"Leads utilisés par jour"}</h3>
                <GraphUsersWork daysUsageData={daysUsageData} />
            </Grid>

            <Grid item xs={12} key="prospecting_results_per_user">
                <h3 className="title" style={{ color }}>{`Résultats par collaborateur (${duration} jours)`}</h3>
                <br />
                <TableUsersUsage usersUsageData={usersUsageData} />
                <br />
            </Grid>

            <Grid item xs={12} key="prospecting_results_per_day">
                <h3 className="title" style={{ color }}>{"Résultats par jour"}</h3>
                <br />
                <TableDaysUsage daysUsageData={daysUsageData} />
                <br />
            </Grid>
        </Grid>
    );
}

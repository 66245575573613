import React, { useContext, useEffect, useState } from "react";

import {
    clearNRP,
    getCompanyLeadsCount,
    getCompanyUsers,
    getNRPCount,
    getUnassignedCompanyLeadsCount,
    getUser
} from "./api/axios";

import { Box, Grid, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import AuthContext from "./context/AuthProvider";
import { UserType } from "./context/enums";
import { useDifferentLists } from './lib/useDifferentLists';

import Collaborator from "./component/admin/collaborator";
import "./style/Collaborators.css";

function Stat(props) {
    const { label, value, color } = props;

    return (
        <div className="stat">
            <p className="stat-label">{label}</p>
            <p className="stat-value" style={{ color }}>{value}</p>
        </div>);
}

// function Input(props) {
//     const { label, onChange } = props;

//     return (
//         <div className="input">
//             <p className="input-label">{label}</p>
//             <input className="input-value" placeholder={label} onChange={onChange} />
//         </div>);
// }

export default function Collaborators({ setToast, setConfirmation }) {

    const { auth, listsIds, color } = useContext(AuthContext);

    const [collaborators, setCollaborators] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState("__undefined__");

    const [manager, setManager] = useState("");
    const [nrpCount, setNrpCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [notAssignedCount, setNotAssignedCount] = useState(0);

    const isManager = auth.user_type === UserType.MANAGER;

    useEffect(() => {
        if (!auth) {
            return;
        }

        if (auth.user_type === UserType.MANAGER) {
            getCompanyUsers(auth.company_id).then(res => setCollaborators(res.data));
            getCompanyLeadsCount(auth.company_id).then((res) => { if (res) setTotalCount(res.data) });
            getUnassignedCompanyLeadsCount(auth.company_id).then((res) => { if (res) setNotAssignedCount(res.data) });
            getNRPCount(auth.company_id).then((res) => { if (res) setNrpCount(res.data) });
        } else if (auth.user_type === UserType.USER) {
            getUser(auth.manager_id).then((res) => { if (res) setManager(res.data) }); // todo(3) rename getuserbyid
        }
    }, [auth]);

    const [listIdIdx, getAccessibleListsIds, setListIdIdx, allLists] = useDifferentLists({ auth, accessibleListIds: listsIds });

    const getCollaborateurComponent = () => {
        if (selectedCollaborator === "__undefined__") {
            return null;
        }

        const collaborateurRows = collaborators.filter(colab => colab.id === selectedCollaborator);
        if (collaborateurRows.length === 0) {
            return null;
        }

        const collaborateurInfo = collaborateurRows[0];
        return <Grid item xs={12} key={`${listIdIdx}_${collaborateurInfo.id}`}>
            <Collaborator
                key={`${listIdIdx}_${collaborateurInfo.id}`}
                user={collaborateurInfo}
                setToast={setToast}
                setConfirmation={setConfirmation}
                list={list}
                color={color}
                auth={auth} />
        </Grid>;
    }

    const getCentralComponent = (list) => {
        return <div className="collaborators-list">
            <Grid container spacing={2} style={{ padding: '10px' }}>
                <Grid item xs={12} key="select_worker">
                    <InputLabel>Sélectionnez un collaborateur:</InputLabel>
                    <Select value={selectedCollaborator} onChange={ev => setSelectedCollaborator(ev.target.value)}>
                        <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                        {collaborators.map((collab) => {
                            return <MenuItem key={collab.id} value={collab.id}>{`${collab.firstname} ${collab.lastname}`}</MenuItem>;
                        })}
                    </Select>
                    <br />
                    <br />
                </Grid>
                {getCollaborateurComponent()}
            </Grid>
        </div>;
    }

    const getTabsComponents = () => {
        return getAccessibleListsIds().map((listId, idx) => {
            let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];
            return <Tab key={listId} label={list.name} sx={{ color: '#090425', '&.Mui-selected': { color: '#090425' } }} />;
        });
    }

    const getMainComponent = (list) => {
        if (!auth) {
            return null;
        }

        if (allLists === null) {
            return null;
        }

        if (getAccessibleListsIds().length < 2) {
            return getCentralComponent(list);
        }

        return <div>
            <Box style={{ paddingBottom: 30 }}>
                <Tabs
                    value={listIdIdx}
                    onChange={(event, newViewIdx) => {
                        setListIdIdx(newViewIdx);
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: '#090425' } }}
                    aria-label="scrollable auto tabs example">
                    {getTabsComponents()}
                </Tabs>
            </Box>
            {getCentralComponent(list)}
        </div>
    }

    if (!auth) {
        return null;
    }

    if (allLists === null) {
        return null;
    }

    const listId = getAccessibleListsIds()[listIdIdx];
    let list = allLists?.filter((listDetails) => listDetails.id === listId)[0];

    const handCleanNRPClick = () => {
        clearNRP(auth.company_id)
            .then(() => getNRPCount(auth.company_id))
            .then((res) => {
                setToast({ text: "NRP retirés.", severity: "success" });
                setNrpCount(res.data);
            }).catch((err) => {
                console.error(err);
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
            });
    }

    return (
        <>
            <h1 className="title" style={{ color }}>{isManager ? "Collaborateurs" : "Manager"}</h1>
            {!isManager && <div className="container-manager">
                <p className="title-manager">{manager.firstname + " " + manager.lastname}</p>
            </div>}
            {isManager && <div className="leads-stat">
                <Stat label={"Total leads"} value={totalCount} color={color} />
                <Stat label={"Leads non assigné"} value={notAssignedCount} color={color} />
            </div>}
            {isManager && <div className="nrp-col">
                <p className="nrps" style={{ color }}>{nrpCount + " NRP"}</p>
                <input className="actions" onClick={handCleanNRPClick} type="button" value={"Récupérer tous les NRP"} style={{ backgroundColor: color }} />
            </div>}
            {getMainComponent(list)}
        </>
    );
}

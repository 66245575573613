import axios from 'axios';
import { getBackendHost } from '../lib/config';


const URL = `${getBackendHost()}/v1/la/`
const axiosInstance = axios.create({
    baseURL: URL
});


export async function getUserLeads(id, listId, search, status, limit, offset) {
    try {
        const json = JSON.stringify({ search: search, list_id: listId, status: status, limit: limit, offset: offset });
        const res = await axiosInstance.post("leads/users/" + id, json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getCompanyLeads(companyId, listId, search, status, limit, offset) {
    const json = JSON.stringify({ company_id: companyId, search: search, list_id: listId, status: status, limit: limit, offset: offset });
    return axiosInstance.put("get/company/leads", json,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
}

export async function getUserLeadsCount(userId) {
    try {
        const res = await axiosInstance.get("get/user/leads/count/" + userId);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getUser(userId) {
    return axiosInstance.get("get/user/" + userId);
}

export async function getUserEmail(email) {
    try {
        const json = JSON.stringify({ email: email })
        const res = await axiosInstance.post("users/email", json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getNRP(companyId) {
    try {
        const json = JSON.stringify({ company_id: companyId });
        const res = await axiosInstance.put("nrp", json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function clearNRP(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("clear/nrp", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getNRPCount(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/nrp/count", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllCount(id) {
    try {
        const res = await axiosInstance.post("all/count/manager/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getAllCompanyLeadsByStatus(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/all/company/leads/by/status", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllCountUser(id) {
    try {
        const res = await axiosInstance.post("all/count/user/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getLeadsCountByDepartement(json) {
    return axiosInstance.put("get/available/leads/count/by/departement", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updateLead(leadId, json) {
    try {
        const res = await axiosInstance.post("update/lead/" + leadId, json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function assignLeads(json) {
    return axiosInstance.put("assign/leads", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}


export async function removeLeads(id) {
    try {
        const res = await axiosInstance.post("remove/leads/" + id,);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getCollaborators(id) {
    try {
        const res = await axiosInstance.get("collaborators/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function addWorker(firstname, lastname, email, password, companyId, userType) {
    const json = JSON.stringify({
        firstname,
        lastname,
        email,
        password,
        user_type: userType,
        company_id: companyId
    });

    return axiosInstance.post("add/user", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updatePassword(email, password) {
    const json = JSON.stringify({
        email,
        password
    });

    return axiosInstance.post("update/password", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllUsers(companyId) {
    const json = JSON.stringify({ company_id: companyId });

    return axiosInstance.put("users", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getLeadById(id) {
    const json = JSON.stringify({ id });

    return axiosInstance.put("get/lead/by/id", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllLists() {
    return axiosInstance.get("get/all/list");
}

export async function getAllListsAccessibles(companyId) {
    const json = JSON.stringify({ company_id: companyId });

    return axiosInstance.put("get/list/accessibles", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllBienLeadsPhones() {
    return axiosInstance.get("get/all/bien/leads/phones");
}

export async function getCompanyWorkDone(companyId, duration) {
    const json = JSON.stringify({ company_id: companyId, duration });

    return axiosInstance.put("get/company/work/done", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllCompanies() {
    return axiosInstance.get("get/all/companies");
}

export async function getCompanyMetrics(companyId, duration) {
    const json = JSON.stringify({ company_id: companyId, duration });

    return axiosInstance.put("get/company/metrics", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyUsersMetrics(companyId, duration) {
    const json = JSON.stringify({ company_id: companyId, duration });

    return axiosInstance.put("get/company/user/metrics", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyUsers(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/users", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyLeadsCountSplit(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/company/leads/count/split", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getCompanyLeadsCount(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/company/leads/count", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getUnassignedCompanyLeadsCount(companyId) {
    const json = JSON.stringify({ company_id: companyId });
    return axiosInstance.put("get/unassigned/company/leads/count", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updateCompanyActivity(companyId, activity) {
    const json = JSON.stringify({ company_id: companyId, activity });
    return axiosInstance.put("update/company/activity", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

import { useEffect, useState } from "react";
import { getCompanyMetrics } from "../../api/axios";


export function useDaysUsage({ companyId, duration }) {
    const [daysUsageData, setDaysUsageData] = useState([]);

    useEffect(() => {
        if (!companyId || companyId === "__undefined__") {
            return;
        }

        setDaysUsageData([]);
        getCompanyMetrics(companyId, duration).then(data => {
            const cleanedData = data.data.map(row => {
                return {
                    day: row.day,
                    work_done: parseInt(row.work_done),
                    rdv_taken: parseInt(row.rdv_taken),
                    signings: parseInt(row.signings),
                };
            });
            setDaysUsageData(cleanedData);
        });
    }, [companyId, duration]);

    return [
        daysUsageData,
    ];
}

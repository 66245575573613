import React from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { findNextMultiple, getArrayMaxValue } from "../../../lib/tools/numbers";

export default function GraphUsersWork({ daysUsageData }) {
    const maxYAxis = findNextMultiple(Math.round(getArrayMaxValue(daysUsageData.map(row => row.work_done)) * 1.2 + 1), 4);
    return (
        <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
                <LineChart
                    data={daysUsageData.map(row => { return { ...row, "Nombre de leads": row.work_done } })}
                    margin={{ top: 40, right: 60, left: 0, bottom: 40 }}>
                    <XAxis dataKey="day" angle={45} height={90} textAnchor="start" />
                    <YAxis domain={[0, maxYAxis]} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Nombre de leads" stroke="#8884d8" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
